<template>
  <div class="hyperion view">
    <app-header :title="'Todos'"/>
    <progress-linear v-if="loading"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">
          {{ currentRole ? currentRole.name : __('myTodos') }}
        </div>
      </div>
      <div class="control-bar__buttons">
        <div class="control-bar__button"
             :class="{'control-bar__button--active': currentRoleIsBookmarked}"
             :title="__('common.add')" @click="toggleRoleBookmark"
             v-slashes
             v-if="currentRole"
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/star.svg"
               alt="bookmark"/>
        </div>

        <div class="control-bar__button" :title="__('common.add')" @click="addTodo" v-slashes
             v-if="canCreate">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>

        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="todo-board mt">
      <roles-tree-nav
          ref="rolesTreeNav"
          :bookmarked-role-ids="bookmarkedRoleIds"
          @roleSelected="handleRoleSelected"
      />
      <todo-column :state="states.open" :todos="todosOpen" :states="states" :show-role="!currentRole"
                   @todoClicked="selectTodo" @todoColumnDragOver="handleTodoColumnDragOver"
                   @todoDragEnd="handleTodoDragEnd"/>
      <todo-column :state="states.inProgress" :todos="todosInProgress" :states="states" :show-role="!currentRole"
                   @todoClicked="selectTodo" @todoColumnDragOver="handleTodoColumnDragOver"
                   @todoDragEnd="handleTodoDragEnd"/>
      <todo-column :state="states.cancelled" :todos="todosCancelled" :states="states" :show-role="!currentRole"
                   @todoClicked="selectTodo" @todoColumnDragOver="handleTodoColumnDragOver"
                   @todoDragEnd="handleTodoDragEnd"/>
      <todo-column :state="states.done" :todos="todosDone" :states="states" :show-role="!currentRole"
                   @todoClicked="selectTodo" @todoColumnDragOver="handleTodoColumnDragOver"
                   @todoDragEnd="handleTodoDragEnd"/>
    </div>
    <edit-todo ref="editTodo" @updated="refresh" @created="refresh"/>
    <todo-aside
        ref="todoAside"
        :todo="selectedTodo"
        :can-edit="canEdit"
        @close="unselectTodo"
        @edit="editTodo"
        @assignToMe="assignToMe"
    />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import apiSecured from '../../api/secured';
import AppHeader from '../../components/AppHeader';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { pageTitleMixin } from "../../mixins/pageTitle";
import { userMixin } from "../../mixins/user";
import EditTodo from "../../components/Community/Todos/EditTodo";
import RolesTreeNav from "../../components/Community/Todos/RolesTreeNav";
import TodoColumn from "../../components/Community/Todos/TodoColumn";
import { isAdmin, isMemberOrLeader } from "../../helpers/userHelpers";
import TodoAside from "../../components/Community/Todos/TodoAside";

const TODOS_BOOKMARKED_ROLES = 'todos_bookmarked_roles';

export default {
  mixins: [notificationsMixin, localizationMixin, pageTitleMixin, userMixin],
  components: {
    TodoAside,
    TodoColumn,
    RolesTreeNav,
    AppHeader,
    EditTodo,
  },
  data: () => ({
    sortBy: 'createdAt',
    sortDesc: false,
    loading: false,
    todos: [],
    currentRole: null,
    filterByState: null,
    filterByAssignedToId: null,
    states: {
      open: 'open',
      inProgress: 'in-progress',
      cancelled: 'cancelled',
      done: 'done',
    },
    selectedTodo: null,
    bookmarkedRoleIds: [],
    draggedOverState: null,
  }),
  computed: {
    todosOpen() {
      return this.todos.filter(todo => todo.state === this.states.open);
    },
    todosInProgress() {
      return this.todos.filter(todo => todo.state === this.states.inProgress);
    },
    todosCancelled() {
      return this.todos.filter(todo => todo.state === this.states.cancelled);
    },
    todosDone() {
      return this.todos.filter(todo => todo.state === this.states.done);
    },
    roles() {
      return this.$refs.rolesTreeNav ? this.$refs.rolesTreeNav.roles : [];
    },
    isMemberOrLeader() {
      if (this.currentRole) {
        return isMemberOrLeader(this.$store.state.user, this.currentRole, this.roles);
      }
      return false;
    },
    canCreate() {
      return this.currentRole && (isAdmin(this.$store.state.user) || this.isMemberOrLeader);
    },
    canEdit() {
      if (isAdmin(this.$store.state.user)) {
        return true;
      } else if (this.currentRole && this.isMemberOrLeader) {
        return true;
      } else if (this.selectedTodo && this.selectedTodo.assignedToId === this.$store.state.user.id) {
        return true;
      }
      return false;
    },
    currentRoleIsBookmarked() {
      if (!this.currentRole) {
        return false;
      }
      return this.bookmarkedRoleIds.includes(this.currentRole.id);
    },
    bookmarkedRoles() {
      return this.roles.filter(role => this.bookmarkedRoleIds.includes(role.id));
    }
  },
  methods: {
    toggleRoleBookmark() {
      if (this.currentRole) {
        if (!this.currentRoleIsBookmarked) {
          this.bookmarkedRoleIds = [...this.bookmarkedRoleIds, this.currentRole.id];
        } else {
          let roleIds = [...this.bookmarkedRoleIds];
          let index = roleIds.indexOf(this.currentRole.id);
          roleIds.splice(index, 1);
          this.bookmarkedRoleIds = roleIds;
        }
        localStorage.setItem(TODOS_BOOKMARKED_ROLES, this.bookmarkedRoleIds.join(','));
      }
    },
    selectTodo(todo) {
      this.selectedTodo = todo;
    },
    unselectTodo() {
      this.selectedTodo = null;
    },
    handleRoleSelected(role) {
      this.currentRole = role;
      this.refresh();
    },
    handleTodoColumnDragOver(state) {
      this.draggedOverState = state;
    },
    handleTodoDragEnd(todo) {
      this.changeState(todo, this.draggedOverState);
    },
    addTodo() {
      if (!this.currentRole) {
        return;
      }
      this.$refs.editTodo.showCreate({ roleId: this.currentRole.id, state: this.states.open, priority: 0 });
    },
    editTodo(todo) {
      this.$refs.editTodo.showEdit(todo);
    },
    assignToMe(todo) {
      let title = this.__('assignToMeQuestionTitle');
      let text = this.__('assignToMeDefaultQuestionText');

      if (todo.assignedToId) {
        text += this.__('assignToMeConflictQuestionText').replace('$username', todo.assignedTo.userName);
      }

      Swal.fire({
        title,
        html: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.__('letMeDuIt')
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let endpoint = '/community/todos/todo/' + todo.id;
          const payload = [{
            op: 'replace',
            path: `/assignedToId`,
            value: this.$store.state.user.id
          }]
          apiSecured.patch(endpoint, payload).then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.saved'));
            this.refresh();
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.refresh();
          });
        }
      });
    },
    changeState(todo, state) {
      this.loading = true;
      let endpoint = '/community/todos/todo/' + todo.id;
      const payload = [{
        op: 'replace',
        path: `/state`,
        value: state
      }]
      apiSecured.patch(endpoint, payload).then((res) => {
        this.loading = false;
        this.showSuccessNotification(this.__('common.saved'));
        this.refresh();
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
        this.refresh();
      });
    },
    deleteTodo(todo) {
      Swal.fire({
        title: this.__('common.are_you_sure'),
        text: this.__('common.do_you_want_to_delete'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.__('common.yes_delete_it')
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let endpoint = '/community/todos/todo/' + todo.id;
          apiSecured.delete(endpoint).then((res) => {
            this.loading = false;
            this.showSuccessNotification(this.__('common.deleted'));
            this.refresh();
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.refresh();
          });
        }
      });
    },
    async refresh() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        pageSize: -1,
        roleId: this.currentRole ? this.currentRole.id : null,
        state: this.filterByState,
        assignedToId: this.currentRole ? null : this.$store.state.user.id,
        orderBy: `priority desc, createdAt asc`,
      }
      await apiSecured.get('/community/todos/todo', { params }).then(async (res) => {
        this.todos = await this.enrichRecords(res.data);
        this.loading = false;
        this.showInfoNotification(this.__('common.data_loaded'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error)
      });

    },
    async enrichRecords(records) {
      const userIds = [];
      const roleIds = [];
      records.forEach(({ creatorId, assignedToId, roleId, todoAccesses }) => {
        if (!userIds.includes(creatorId)) {
          userIds.push(creatorId)
        }
        if (!userIds.includes(assignedToId)) {
          userIds.push(assignedToId)
        }
        if (!roleIds.includes(roleId)) {
          roleIds.push(roleId)
        }
        todoAccesses.forEach(({ roleId }) => {
          if (!roleIds.includes(roleId)) {
            roleIds.push(roleId)
          }
        })
      });

      let roles = [];
      let users = [];

      if (roleIds.length > 0) {
        await apiSecured.get(`/identity/role/(${roleIds})`).then(async (res) => {
          roles = res.data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      }
      if (userIds.length > 0) {
        await apiSecured.get(`/community/user/(${userIds})`).then(async (res) => {
          users = res.data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      }

      return records.map(record => {
        record.role = roles.find(role => role.id === record.roleId);
        record.assignedTo = users.find(user => user.id === record.assignedToId);
        record.creator = users.find(user => user.id === record.creatorId);

        record.todoAccesses = record.todoAccesses.map(todoAccess => {
          todoAccess.role = roles.find(role => role.id === todoAccess.roleId);
          return todoAccess;
        })

        return record;
      })
    }
  },
  created() {
    const raw = localStorage.getItem(TODOS_BOOKMARKED_ROLES);
    if (raw) {
      this.bookmarkedRoleIds = raw.split(',');
    }
    this.setPageTitle('Todos');
    this.refresh();
  }
}
</script>