<template>
  <div class="todo"
       @click="handleClicked"
       draggable="true"
       @dragstart="handleDragStart"
       @dragover="handleDragOver"
       @dragend="handleDragEnd"
  >
    <div class="todo__header">
      <div class="todo__title">{{todo.name}}</div>
    </div>
    <div class="todo__body">

      <div class="todo__field">
        <div class="todo__field-label">{{__('priority')}}</div>
        <div class="todo__field-value">
          {{todo.priority}}
        </div>
      </div>

      <div class="todo__field">
        <div class="todo__field-label">{{__('assignedTo')}}</div>
        <div class="todo__field-value">
          <div class="todo__user" v-if="todo.assignedTo">
            <div class="todo__user-avatar" :style="getUserAvatarStyle(todo.assignedTo)"></div>
            <div class="todo__user-name">{{todo.assignedTo.userName}}</div>
          </div>
          <div class="todo__user" v-else>
            <div class="todo__user-avatar"></div>
            <div class="todo__user-name text--error">{{__('notAssigned')}}</div>
          </div>
        </div>
      </div>

      <div class="todo__field">
        <div class="todo__field-label">{{__('restrictViewAccessTo')}}</div>
        <div class="todo__field-value">
          {{ todo.todoAccesses.map(viewAccess => viewAccess.role.name).join(', ') }}
        </div>
      </div>

      <div class="todo__field" v-if="showRole">
        <div class="todo__field-label">Board</div>
        <div class="todo__field-value">
          {{ todo.role.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localizationMixin } from "../../../mixins/localization";
import { getUserAvatarStyle } from "../../../helpers/userHelpers";

export default {
  name: "Todo",
  mixins: [localizationMixin],
  props: ['todo', 'showRole'],
  methods: {
    getUserAvatarStyle,
    handleClicked() {
      this.$emit('click', this.todo);
    },
    handleDragStart() {
      this.$emit('todoDragStart')
    },
    handleDragOver() {
      this.$emit('todoDragOver')
    },
    handleDragEnd() {
      this.$emit('todoDragEnd');
    }
  }
}
</script>