<template>
  <div class="edit-role-application">
    <my-dialog :show="show" @close="close" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ __('editTodo') }}</div>
          <div class="card__button" v-slashes @click="update" v-if="changedProperties.length > 0 && !loading" :title="__('common.save_changes')">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/check.svg" alt="save"/>
          </div>
          <div class="card__button" v-slashes @click="close" :title="__('common.close')"><img
              src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/></div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <text-field
                  v-model="record.name"
                  :label="'Name'"
                  :error-messages="errors.name"
                  :prepend-icon="isChanged('name') ? 'pencil' : null"
                  @input="registerChange('name')"
              />
            </div>

            <div class="flex xs12">
              <p>{{ __('entity.common.description') }}</p>
              <ckeditor v-model="record.description"
                        :title="__('entity.common.description')"
                        :editor="editor"
                        :config="editorConfig"
                        @input="registerChange('description')"></ckeditor>
            </div>

            <div class="flex xs12 mt">
              <text-field
                  v-model="record.priority"
                  :label="__('priority')"
                  :error-messages="errors.priority"
                  :prepend-icon="isChanged('priority') ? 'pencil' : null"
                  @input="registerChange('priority')"
              />
            </div>

            <div class="flex xs12">
              <label>{{ __('state') }}</label>
              <single-select
                  :items="states"
                  v-model="record.state"
                  :append-icon="isChanged('state') ? 'pencil' : null"
                  @input="registerChange('state')"
              />
            </div>

            <div class="flex xs12 mt">
              <label>{{ __('role') }}</label>
              <select-reference
                  v-model="record.roleId"
                  :endpoint="'/identity/role'"
                  :nothing-selected-message="__('no_role_selected')"
                  :prepend-icon="isChanged('roleId') ? 'pencil' : ''"
                  :readonly="!isAdmin"
                  @input="registerChange('roleId')"
              />
            </div>

            <div class="flex xs12">
              <label>{{ __('assignedTo') }}</label>
              <select-reference
                  v-model="record.assignedToId"
                  :endpoint="'/community/user'"
                  :field-title="'userName'"
                  :field-icon="'avatar'"
                  :nothing-selected-message="__('common.no_user_selected')"
                  :prepend-icon="isChanged('assignedToId') ? 'pencil' : ''"
                  @input="registerChange('assignedToId')"
              />
            </div>

            <div class="flex xs12 mt">
              <label>{{ __('restrictViewAccessTo') }}</label>
              <multi-select
                  v-model="record.todoAccessesRoleIds"
                  :endpoint="'/identity/role'"
                  :field-icon="null"
                  :prepend-icon="isChanged('todoAccessesRoleIds') ? 'pencil' : ''"
                  @input="registerChange('todoAccessesRoleIds')"
              />
            </div>

            <div class="flex xs12">
              <div class="layout">
                <div class="flex xs6 mt">
                  <div class="btn btn--success" :loading="loading"
                       :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--primary" @click="close" v-slashes>{{
                      __('common.close')
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '../../../mixins/localization';
import { editFormMixin } from "../../../mixins/editForm";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { isAdmin } from "../../../helpers/userHelpers";

export default {
  props: ['role'],
  mixins: [localizationMixin, editFormMixin],
  data: () => ({
    closeAfterCreated: true,
    endpoint: '/community/todos/todo',
    emptyRecord: {
      id: null,
      name: '',
      description: '',
      state: '',
      priority: 0,
      roleId: '',
      assignedToId: '',
      todoAccessesRoleIds: [],
      todoAccesses: [],
    },
    states: [],
    editor: ClassicEditor,
    editorConfig: {
      toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      }
    }
  }),
  methods: {
    postProcess() {
      this.record.todoAccessesRoleIds = this.record.todoAccesses.map(todoAccess => {
        return todoAccess.roleId;
      });
    },
    isAdmin
  },
  created() {
    this.states = [
      { value: 'open', title: this.__('open') },
      { value: 'in-progress', title: this.__('in-progress') },
      { value: 'done', title: this.__('done') },
      { value: 'cancelled', title: this.__('cancelled') },
    ]
  }
}
</script>

<style scoped>

</style>