export const isAdmin = (user) => {
  return user.roles.includes('Administrator');
}

export const isMemberOrLeader = (user, role, roles) => {
  if (user.roles.includes(role.name)) {
    return true;
  }
  return isLeader(user, role, roles);
}

export const isLeader = (user, role, roles) => {
  if ([role.primeId, role.viceId].includes(user.id)) {
    return true;
  }
  if (role.parentId) {
    const parent = roles.find(potentialParent => potentialParent.id === role.parentId);
    if (parent) {
      return isLeader(user, parent, roles);
    }
  }
  return false;
}

export const getUserAvatarStyle = (user) => {
  if(user.avatar) {
    return {
      backgroundImage: `url('${user.avatar}')`
    };
  }
  return {};
}