<template>
  <div class="side-nav tile">
    <div class="single-select single-select--full-width mb">
      <div class="single-select__item mb"
           :class="{'single-select__item--selected': selectedRole === null}"
           @click="selectRole(null, true)"
           v-slashes
      >
        <div class="single-select__item-title">{{__('myTodos')}}</div>
      </div>
      <div v-for="role in roles.filter(fRole => bookmarkedRoleIds.includes(fRole.id))"
           class="single-select__item"
           :class="{'single-select__item--selected': selectedRole && role.id === selectedRole.id}"
           @click="selectRole(role, true)"
           v-slashes
      >
        <div class="single-select__item-title">{{role.name}}</div>
      </div>
      <div class="seperator"></div>
    </div>
    <v-jstree
        v-if="rolesTree.length > 0"
        :key="selectedRole ? selectedRole.id : '----'"
        ref="treeNav"
        :data="rolesTree"
        :size="'large'"
        :text-field-name="'name'"
        :value-field-name="'id'"
        :children-field-name="'children'"
        @item-click="itemClicked"
    >
      <template scope="_">
        <div style="display: inherit; width: 200px" :title="_.model.name">
          {{ _.model.name }}
        </div>
      </template>
    </v-jstree>
  </div>
</template>

<script>
import VJstree from 'vue-jstree';
import apiSecured from "../../../api/secured";
import { notificationsMixin } from "../../../mixins/notifications";
import { localizationMixin } from "../../../mixins/localization";

const LOCAL_STORAGE_SELECTED_ROLE_ID = 'todos_selected_role_id';

export default {
  components: {
    VJstree
  },
  mixins: [notificationsMixin, localizationMixin],
  props: ['bookmarkedRoleIds'],
  data: () => ({
    expanded: [],
    selectedRole: null,
    roles: [],
    rolesTree: [],
  }),
  methods: {
    itemClicked(vm, model, event) {
      this.selectRole(model);
    },
    selectRole(role, resetTree = false) {
      this.roles.forEach(fRole => {
        fRole.selected = false;
      })
      if (role) {
        role.selected = true;
      }
      this.selectedRole = role;
      this.$emit('roleSelected', role);
      localStorage.setItem(LOCAL_STORAGE_SELECTED_ROLE_ID, role ? role.id : null);
      if (resetTree) {
        this.buildRolesTree()
        this.$nextTick(() => this.$refs.treeNav.$forceUpdate());
      }
    },
    buildRolesTree() {
      this.rolesTree = [];
      const tree = [];
      this.roles.filter(role => role.parentId === null).map(role => {
        role.children = this.getRoleChildren(role.id);
        if (this.selectedRole && this.selectedRole.id === role.id) {
          role.selected = true;
        }
        tree.push(role);
      });

      this.rolesTree = tree;
    },
    getRoleChildren(parentId) {
      return this.roles.filter(role => role.parentId === parentId).map(role => {
        role.children = this.getRoleChildren(role.id);
        if (this.selectedRole && this.selectedRole.id === role.id) {
          role.selected = true;
        }
        return role;
      });
    },
    async refreshRoles() {
      this.loading = true;
      await apiSecured.get('/identity/role', {
        params: {
          pageSize: 10000,
          orderBy: 'name'
        }
      })
          .then((res) => {
            this.roles = res.data;
            const localStorageSelectedRoleId = localStorage.getItem(LOCAL_STORAGE_SELECTED_ROLE_ID);
            if (localStorageSelectedRoleId) {
              const role = this.roles.find(role => role.id === localStorageSelectedRoleId);
              if (role) {
                this.selectRole(role);
              }
            }
            this.buildRolesTree();
            this.loading = false;
            this.showInfoNotification('Roles loaded');
          })
          .catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
    },
  },
  created() {
    this.refreshRoles();
  }
}
</script>