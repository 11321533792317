<template>
  <div class="todo-column"
       v-if="states && state"
       :class="{'todo-column--dragged-over': draggedOver}"
       @dragover="handleDragOver"
       @dragleave="handleDragLeave"
  >
    <div class="todo-column__header" :class="stateClasses">
      <div class="todo-column__icon" :style="stateIconStyle"></div>
      <div class="todo-column__title">{{__(state)}}</div>
    </div>
    <todo
        v-for="todo in todos"
        :key="todo.id"
        :todo="todo"
        :show-role="showRole"
        @click="handleTodoClicked"
        @todoDragOver="handleDragOver"
        @todoDragEnd="handleTodoDragEnd(todo)"
    />
  </div>
</template>

<script>
import { localizationMixin } from "../../../mixins/localization";
import Todo from "./Todo";

export default {
  name: 'TodoColumn',
  components: { Todo },
  mixins: [localizationMixin],
  props: ['todos', 'state', 'states', 'showRole'],
  data: () => ({
    draggedOver: false,
  }),
  computed: {
    stateIconStyle() {
      return {
        backgroundImage: `url('https://cdn.hyperion-corporation.de/ui/svgs/state-${this.state}.svg')`,
      }
    },
    stateClasses() {
      return {
        'todo-column__header--open': this.state === this.states.open,
        'todo-column__header--in-progress': this.state === this.states.inProgress,
        'todo-column__header--done': this.state === this.states.done,
        'todo-column__header--cancelled': this.state === this.states.cancelled,
      }
    },
  },
  methods: {
    handleTodoClicked(todo) {
      this.$emit('todoClicked', todo);
    },
    handleTodoDragEnd(todo) {
      this.$emit('todoDragEnd', todo);
    },
    handleDragOver() {
      this.draggedOver = true;
      this.$emit('todoColumnDragOver', this.state);
    },
    handleDragLeave() {
      this.draggedOver = false;
      this.$emit('todoColumnDragLeave', this.state);
    }
  }
}
</script>