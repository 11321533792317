<template>
  <my-aside :show="todo !== null" @close="handleClose">
    <div class="main-content-padding" v-if="todo">
      <div class="layout layout--wrap">
        <div class="flex xs6"><div class="btn btn--info" v-if="canAssignToMe" @click="assignToMe">{{__('assignToMe')}}</div></div>
        <div class="flex xs6"><div class="btn" v-if="canEdit" @click="edit">{{__('edit')}}</div></div>
      </div>
      <div class="bodytext">
        <h2>{{todo.name}}</h2>
        <div v-html="todo.description"></div>
      </div>
      <div class="todo__body">

        <div class="todo__field">
          <div class="todo__field-label">{{__('priority')}}</div>
          <div class="todo__field-value">
            {{todo.priority}}
          </div>
        </div>

        <div class="todo__field">
          <div class="todo__field-label">{{__('assignedTo')}}</div>
          <div class="todo__field-value">
            <div class="todo__user" v-if="todo.assignedTo">
              <div class="todo__user-avatar" :style="getUserAvatarStyle(todo.assignedTo)"></div>
              <div class="todo__user-name">{{todo.assignedTo.userName}}</div>
            </div>
            <div class="todo__user" v-else>
              <div class="todo__user-avatar"></div>
              <div class="todo__user-name text--error">{{__('notAssigned')}}</div>
            </div>
          </div>
        </div>

        <div class="todo__field">
          <div class="todo__field-label">{{__('creator')}}</div>
          <div class="todo__field-value">
            <div class="todo__user">
              <div class="todo__user-avatar" :style="getUserAvatarStyle(todo.creator)"></div>
              <div class="todo__user-name">{{todo.creator.userName}}</div>
            </div>
          </div>
        </div>

        <div class="todo__field">
          <div class="todo__field-label">{{__('restrictViewAccessTo')}}</div>
          <div class="todo__field-value">
            {{ todo.todoAccesses.map(viewAccess => viewAccess.role.name).join(', ') }}
          </div>
        </div>

        <div class="todo__field">
          <div class="todo__field-label">{{__('entity.common.created_at')}}</div>
          <div class="todo__field-value" v-html="renderDatetime(todo.createdAt)"></div>
        </div>

        <div class="todo__field">
          <div class="todo__field-label">{{__('entity.common.updated_at')}}</div>
          <div class="todo__field-value" v-html="renderDatetime(todo.updatedAt)"></div>
        </div>
      </div>
    </div>
  </my-aside>
</template>

<script>
import { getUserAvatarStyle } from "../../../helpers/userHelpers";
import { localizationMixin } from "../../../mixins/localization";
import { renderDatetime } from "../../../helpers/generalHelpers";

export default {
  name: "TodoAside",
  mixins: [localizationMixin],
  props: ['todo','canEdit'],
  computed: {
    canAssignToMe() {
      return this.canEdit && this.todo.assignedToId !== this.$store.state.user.id;
    }
  },
  methods: {
    getUserAvatarStyle,
    renderDatetime,
    handleClose() {
      this.$emit('close');
    },
    assignToMe() {
      this.$emit('assignToMe', this.todo);
    },
    edit() {
      this.$emit('edit', this.todo);
    }
  }
}
</script>